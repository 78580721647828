.containerEnviado{
    min-width: 100vw;
    min-height: 100vh;
    background-image: linear-gradient(to bottom , #FFF 0%,#F8EEE1 5%,#F8EEE1 30%, #C3A87F 100%);


    .headerEnviado{
        min-width: 100vw;
        min-height: 20vh;
        display: flex;
        flex-direction: row;

        .logoEnviado{
            min-width: 20vw;
            min-height: 15vh;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                min-width: 10vw;
            }
        }
        .textHeader{
            min-width: 60vw;
            max-width: 60vw;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font-size: 2rem;
            min-height: 15vh;
            color: #AF8E5B;
            font-family: Gabi;
        }
    }
    .imagenEnviado{
        
        min-height: 45vh;
        min-width: 100vw;
        background-image: url('../../img/fondoenviado.png');
    }
    .textEnviado{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-width: 90vw;
        min-height: 10vh;
        label{
            font-size: 1.2rem;
            font-family: Avenir;
            font-style: oblique;
            font-weight: 400;
            min-width: 100%;
            text-align: center;
        }
    }
    .hablarEspecialista{
        min-width: 90vw;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 15vh;
        button{
            min-width: 90vw;
            min-height: 8vh;
            border-radius: 2vw;
            border-color: #FFF;
            background-color: #AF8E5B;
            font-size: 1.5rem;
            color: #FFF;
            font-family: Avenir;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            label{
                min-height: 5vh;
                line-height: 5vh;
                margin-left: 0.5rem;
                font-size: 1.2rem;
                letter-spacing: 0.05rem;
                font-family: Avenir;
                font-weight: 600;
            }
        }
    }


    .footer{
        min-width: 90vw;
        display: flex;
        flex-direction: column;
        div{
            display: flex;
            flex-direction: column;
            text-align: center;
            color: #FFF;
            justify-content: center;
            align-items: center;
            label{
                min-width: 95vw;
                max-width: 95vw;          
                font-size: .9rem;        
                font-family: Avenir;
                font-weight: 600;
                line-height: 1.5rem;
                a{
                    color: #FFF;
                }
            }
            img{
                margin-top: 5vh;
                margin-bottom: 5vh;
                max-width: 50vw;
            }
        }
    }
}


@media (min-width: 960px) {
    .containerEnviado{
        min-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to bottom , #FFF 0%,#F8EEE1 5%,#F8EEE1 30%, #C3A87F 100%);
        background-color: red;
    
        .headerEnviado{
            min-width: 100vw;
            min-height: 20vh;
            display: flex;
            flex-direction: row;
            border: 1px solid red;
            .logoEnviado{
                min-width: 20vw;
                min-height: 15vh;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    min-width: 10vw;
                }
            }
            .textHeader{
                min-width: 60vw;
                max-width: 60vw;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 2rem;
                min-height: 15vh;
                color: #AF8E5B;
                font-family: Gabi;
            }
        }
        .imagenEnviado{
            
            min-height: 45vh;
            min-width: 100vw;
            background-image: url('../../img/fondoenviado.png');
        }
        .textEnviado{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 90vw;
            min-height: 10vh;
            label{
                font-size: 1.2rem;
                font-family: Avenir;
                font-style: oblique;
                font-weight: 400;
                min-width: 100%;
                text-align: center;
            }
        }
        .hablarEspecialista{
            min-width: 90vw;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 15vh;
            button{
                min-width: 90vw;
                min-height: 8vh;
                border-radius: 2vw;
                border-color: #FFF;
                background-color: #AF8E5B;
                font-size: 1.5rem;
                color: #FFF;
                font-family: Avenir;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                label{
                    min-height: 5vh;
                    line-height: 5vh;
                    margin-left: 0.5rem;
                    font-size: 1.2rem;
                    letter-spacing: 0.05rem;
                    font-family: Avenir;
                    font-weight: 600;
                }
            }
        }
    
    
        .footer{
            min-width: 90vw;
            display: flex;
            flex-direction: column;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                label{
                    min-width: 95vw;
                    max-width: 95vw;          
                    font-size: .9rem;        
                    font-family: Avenir;
                    font-weight: 600;
                    line-height: 1.5rem;
                    a{
                        color: #FFF;
                    }
                }
                img{
                    margin-top: 5vh;
                    margin-bottom: 5vh;
                    max-width: 50vw;
                }
            }
        }
    }
    
    
    
}