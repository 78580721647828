.generalContainerLanding{
    min-width: 100vw;
    max-width: 100vw;
    min-height: 100vh;
    background-image: linear-gradient(to top, #C8B29B 0%, #C8B29B 30%, #C8B29B 50%, #FFF 80%,#FFF 100% );

    .imagenLogo{
        position: absolute;
        min-width: 50vw;
        margin-top: 112.5vh;
        margin-left: 25vw;
    }
    .sectionOne{
        min-height: 45vh;
        background-image: url('../../landing/fondoEnviadoMobil.png');
        background-size: cover;

        background-position: bottom;
        background-size: 110%;
        background-repeat: no-repeat;


        .header{
            display: flex;
            .footersectionOne{
                min-width: 80vw;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                align-items: flex-end;
                label{
                    min-width: 75vw;
                    max-width: 75vw;
                }
                .texone{
                    display: none;
                    
                }
                .textwo{
                    color: #fff;
                    font-size: 1.3rem;
                    min-height: 8vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: Avenir;
                    font-style: oblique;
                }
            }
            .footersectionTwo{
                position: absolute;
                min-width: 20vw;
                min-height: 10vh;
                max-height: 10vh;
                display: flex;
                justify-content: center;
                align-items: center;
                img{
                    min-width: 10vw;
                }
            }
        }
      
     
      
    }


    .sectionCard{
        max-width: 100vw;
        z-index: 2;
        min-height: 10vh;
        padding-bottom: 5vh; 
        padding-top: 5vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 100vw;
        font-size: 1.8rem;
        text-align: center;
        color: #6E605D;
        font-family: Gabi;
        font-weight: 600;       
        font-style: normal;
        background-image: linear-gradient(to top, transparent 10%   ,transparent 100% );
        text{
            font-family: Avenir;
            font-weight: 400;       

        }
    }


    .sectionForm{
        
        z-index: 5;
        max-width: 100vw;
        display: flex;
        .filtro{    
            display: none;            
        }
        form{ 
            display: flex;
            flex-direction: column;
            align-items: center;
            z-index: 5;

            img{
                
            }
            div{
                z-index: 5;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-height: 10vh;
                font-family: Avenir;
                
                .subtitleUno{
                    font-weight: 400;
                    margin-top: 2vh;
                    font-size: 0.9rem;
                    text-align: center;
                    letter-spacing: 0.05rem;
                    max-width: 100vw;
                    min-width: 100vw;
                    color: #FFF;
                    font-family: Avenir;
                    font-style: oblique;
                    margin-bottom: 4vh;
                    
                }

                
                input,select{             
                    display: none;
                }
                select{
                    max-width: 20vw;
                    min-width: 20vw;
                    display: none;
                }
                label{
                    z-index: 5;
                    font-size: 1rem;
                    color:#FFF;
                    letter-spacing: 0.2rem;

     
                }
                input::placeholder,  select::placeholder{
                    color: #FFF;
                    font-weight: 400;
                    font-size: 0.9rem;
                    text-align: left;
                    display: none;
                }
            }
            .Logos{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .logoUno{
                    text-align: center;
                    img{
                        max-width: 25vw;
                        
                    }

                    label{
                        min-width: 40vw;
                        max-width: 40vw;
                        font-size: 0.8rem;
                        margin-top: 0.5rem;
                    }
                }
                .logoDos{
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img{
                        max-width: 20vw;

                    }
                    label{

                        font-size: 0.8rem;
                        min-width: 40vw;
                        max-width: 40vw;
                        margin-top: 0.8rem;
                    }
                }
            

            }


            
            .entradasRadios{                    
                display: none;
            }

            .agendacita{
                display: none;
                label{
                
                }
                hr{
                    min-width: 15vw;
                }
            }
        }

    }
   
    .giftcard{
        display: none;
        button{
        font-weight: 600;
        }
    }

    .footer{
        position: absolute;
        min-width: 100vw;
        display: flex;
        z-index: 10;
        margin-top: -25vh;
        flex-direction: column;
        div{
            display: flex;
            flex-direction: column;
            text-align: center;
            color: #FFF;
            justify-content: center;
            align-items: center;
            margin-bottom: 5vh;
            label{
                min-width: 95vw;
                max-width: 95vw;          
                font-size: .9rem;        
                font-family: Avenir;
                font-weight: 400;
                line-height: 1.5rem;
              
                a{
                    color: #FFF;
                }
                
            }
            img{
                display: none;                 
            }
        }
    }
    
}

















/*RESOLUCIONES MOBILES*/


@media (min-width:360px) and (min-height: 640px)  {
    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #968B6F 0%, #C8B29B 30%, #C8B29B 50%, #FFF 80%,#FFF 100% );
    
        .imagenLogo{
            position: absolute;
            min-width: 50vw;
            margin-top: 112.5vh;
            margin-left: 25vw;
        }
        .sectionOne{
            min-height: 45vh;
            background-image: url('../../landing/fondoEnviadoMobil.png');
            background-size: cover;
    
            background-position: bottom;
            background-size: 100%;
            background-repeat: no-repeat;
    
    
            .header{
                display: flex;
                .footersectionOne{
                    min-width: 80vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    label{
                        min-width: 75vw;
                        max-width: 75vw;
                    }
                    .texone{
                        display: none;
                        
                    }
                    .textwo{
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{
                    position: absolute;
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        min-width: 10vw;
                    }
                }
            }
          
         
          
        }
    
    
        .sectionCard{
            max-width: 100vw;
            z-index: 2;
            min-height: 10vh;
            padding-bottom: 5vh; 
            padding-top: 5vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 100vw;
            font-size: 1.8rem;
            text-align: center;
            color: #6E605D;
            font-family: Gabi;
            font-weight: 600;       
            font-style: normal;
            background-image: linear-gradient(to top, transparent 10%   ,transparent 100% );
            text{
                font-family: Avenir;
                font-weight: 400;       

            }
        }
    
    
        .sectionForm{
            
            z-index: 5;
            max-width: 100vw;
            display: flex;
            .filtro{    
                display: none;            
            }
            form{ 
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 5;

                img{
                    
                }
                div{
                    z-index: 5;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 10vh;
                    font-family: Avenir;
                    
                    .subtitleUno{
                        font-weight: 400;
                        margin-top: 2vh;
                        font-size: 0.9rem;
                        text-align: center;
                        letter-spacing: 0.05rem;
                        max-width: 100vw;
                        min-width: 100vw;
                        color: #FFF;
                        font-family: Avenir;
                        font-style: oblique;
                        margin-bottom: 4vh;
                        
                    }
    
                    
                    input,select{             
                        display: none;
                    }
                    select{
                        max-width: 20vw;
                        min-width: 20vw;
                        display: none;
                    }
                    label{
                        z-index: 5;
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;

         
                    }
                    input::placeholder,  select::placeholder{
                        color: #FFF;
                        font-weight: 400;
                        font-size: 0.9rem;
                        text-align: left;
                        display: none;
                    }
                }
                .Logos{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                 
                    a{
                        text-decoration: none;
                    }
                    .logoUno{
                        text-align: center;
                        img{
                            max-width: 25vw;
                            
                        }

                        label{
                            min-width: 40vw;
                            max-width: 40vw;
                            font-size: 0.8rem;
                            margin-top: 0.5rem;
                        }
                    }
                    .logoDos{
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img{
                            max-width: 20vw;

                        }
                        label{

                            font-size: 0.8rem;
                            min-width: 40vw;
                            max-width: 40vw;
                            margin-top: 0.8rem;
                        }
                    }
                

                }

    
                
                .entradasRadios{                    
                    display: none;
                }
    
                .agendacita{
                    display: none;
                    label{
                    
                    }
                    hr{
                        min-width: 15vw;
                    }
                }
            }
    
        }
       
        .giftcard{
            display: none;
            button{
            font-weight: 600;
            }
        }
    
        .footer{
            position: absolute;
            min-width: 100vw;
            display: flex;
            z-index: 10;
            margin-top: -25vh;
            flex-direction: column;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                margin-bottom: 5vh;
                label{
                    min-width: 95vw;
                    max-width: 95vw;          
                    font-size: .9rem;        
                    font-family: Avenir;
                    font-weight: 400;
                    line-height: 1.5rem;
                  
                    a{
                        color: #FFF;
                    }
                    
                }
                img{
                    display: none;                 
                }
            }
        }
        
    }
    
    
}

@media (min-width:360px) and (min-height: 780px)  {
    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #968B6F 0%, #C8B29B 30%, #C8B29B 50%, #FFF 80%,#FFF 100% );
    
        .imagenLogo{
            position: absolute;
            min-width: 50vw;
            margin-top: 103vh;
            margin-left: 25vw;
        }
        .sectionOne{
            min-height: 45vh;
            background-image: url('../../landing/fondoEnviadoMobil.png');
            background-size: cover;
    
            background-position: bottom;
            background-size: 110%;
            background-repeat: no-repeat;
    
    
            .header{
                display: flex;
                .footersectionOne{
                    min-width: 80vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    label{
                        min-width: 75vw;
                        max-width: 75vw;
                    }
                    .texone{
                        display: none;
                        
                    }
                    .textwo{
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{
                    position: absolute;
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        min-width: 10vw;
                    }
                }
            }
          
         
          
        }
    
    
        .sectionCard{
            max-width: 100vw;
            z-index: 2;
            min-height: 5vh;
            padding-bottom: 3vh; 
            padding-top: 3vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 100vw;
            font-size: 1.8rem;
            text-align: center;
            color: #6E605D;
            font-family: Gabi;
            font-weight: 600;       
            font-style: normal;
            background-image: linear-gradient(to top, transparent 10%   ,transparent 100% );
            text{
                font-family: Avenir;
                font-weight: 400;       
                font-style: oblique;
            }
        }
    
    
        .sectionForm{
            
            z-index: 5;
            max-width: 100vw;
            display: flex;
            .filtro{    
                display: none;            
            }
            form{ 
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 5;

                img{
                    
                }
                div{
                    z-index: 5;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 10vh;
                    font-family: Avenir;
                    
                    .subtitleUno{
                        font-weight: 400;
                        margin-top: 0vh;
                        font-size: 0.9rem;
                        text-align: center;
                        letter-spacing: 0.05rem;
                        max-width: 100vw;
                        min-width: 100vw;
                        color: #FFF;
                        font-family: Avenir;
                        font-style: oblique;
                        margin-bottom: 3vh;
                        font-style: normal;
                    }
    
                    
                    input,select{             
                        display: none;
                    }
                    select{
                        max-width: 20vw;
                        min-width: 20vw;
                        display: none;
                    }
                    label{
                        z-index: 5;
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;

         
                    }
                    input::placeholder,  select::placeholder{
                        color: #FFF;
                        font-weight: 400;
                        font-size: 0.9rem;
                        text-align: left;
                        display: none;
                    }
                }
                .Logos{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                 
                    a{
                        text-decoration: none;
                    }
                    .logoUno{
                        text-align: center;
                        img{
                            max-width: 25vw;
                            
                        }

                        label{
                            min-width: 40vw;
                            max-width: 40vw;
                            font-size: 0.9rem;
                            margin-top: 0.5rem;
                            letter-spacing: 0rem;
                        }
                    }
                    .logoDos{
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img{
                            max-width: 20vw;

                        }
                        label{

                            font-size: 0.9rem;
                            letter-spacing: 0rem;
                            min-width: 30vw;
                            max-width: 30vw;
                            margin-top: 0.8rem;
                        }
                    }
                

                }

    
                
                .entradasRadios{                    
                    display: none;
                }
    
                .agendacita{
                    display: none;                 
                }
            }
    
        }
       
        .giftcard{
            display: none;           
        }
    
        .footer{
            position: absolute;
            min-width: 100vw;
            display: flex;
            z-index: 10;
            margin-top: -25vh;
            flex-direction: column;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                margin-bottom: 5vh;
                label{
                    min-width: 95vw;
                    max-width: 95vw;          
                    font-size: .9rem;        
                    font-family: Avenir;
                    font-weight: 400;
                    line-height: 1.5rem;
                  
                    a{
                        color: #FFF;
                    }
                    
                }
                img{
                    display: none;                 
                }
            }
        }
        
    }
    
  
}

@media (min-width:360px) and (min-height: 800px)  {

  

}

@media (min-width:390px) and (min-height: 844px)  {
    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #968B6F 0%, #C8B29B 30%, #C8B29B 50%, #FFF 80%,#FFF 100% );
    
        .imagenLogo{
            position: absolute;
            min-width: 50vw;
            margin-top: 102vh;
            margin-left: 25vw;
        }
        .sectionOne{
            min-height: 45vh;
            background-image: url('../../landing/fondoEnviadoMobil.png');
            background-size: cover;
    
            background-position: bottom;
            background-size: 110%;
            background-repeat: no-repeat;
    
    
            .header{
                display: flex;
                .footersectionOne{
                    min-width: 80vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    label{
                        min-width: 75vw;
                        max-width: 75vw;
                    }
                    .texone{
                        display: none;
                        
                    }
                    .textwo{
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{
                    position: absolute;
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        min-width: 10vw;
                    }
                }
            }
          
         
          
        }
    
    
        .sectionCard{
            max-width: 100vw;
            z-index: 2;
            min-height: 5vh;
            padding-bottom: 3vh; 
            padding-top: 3vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 100vw;
            font-size: 1.8rem;
            text-align: center;
            color: #6E605D;
            font-family: Gabi;
            font-weight: 600;       
            font-style: normal;
            background-image: linear-gradient(to top, transparent 10%   ,transparent 100% );
            text{
                font-family: Avenir;
                font-weight: 400;       
                font-style: oblique;
            }
        }
    
    
        .sectionForm{
            
            z-index: 5;
            max-width: 100vw;
            display: flex;
            .filtro{    
                display: none;            
            }
            form{ 
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 5;

                img{
                    
                }
                div{
                    z-index: 5;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 10vh;
                    font-family: Avenir;
                    
                    .subtitleUno{
                        font-weight: 400;
                        margin-top: 0vh;
                        font-size: 1rem;
                        text-align: center;
                        letter-spacing: 0.05rem;
                        max-width: 100vw;
                        min-width: 100vw;
                        color: #FFF;
                        font-family: Avenir;
                        font-style: oblique;
                        margin-bottom: 3vh;
                        font-style: normal;
                    }
    
                    
                    input,select{             
                        display: none;
                    }
                    select{
                        max-width: 20vw;
                        min-width: 20vw;
                        display: none;
                    }
                    label{
                        z-index: 5;
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;

         
                    }
                    input::placeholder,  select::placeholder{
                        color: #FFF;
                        font-weight: 400;
                        font-size: 0.9rem;
                        text-align: left;
                        display: none;
                    }
                }
                .Logos{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                 
                    a{
                        text-decoration: none;
                    }
                    .logoUno{
                        text-align: center;
                        img{
                            max-width: 25vw;
                            
                        }

                        label{
                            min-width: 40vw;
                            max-width: 40vw;
                            font-size: 1rem;
                            margin-top: 0.5rem;
                            letter-spacing: 0rem;
                        }
                    }
                    .logoDos{
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img{
                            max-width: 20vw;

                        }
                        label{

                            font-size: 1rem;
                            letter-spacing: 0rem;
                            min-width: 30vw;
                            max-width: 30vw;
                            margin-top: 0.8rem;
                        }
                    }
                

                }

    
                
                .entradasRadios{                    
                    display: none;
                }
    
                .agendacita{
                    display: none;                 
                }
            }
    
        }
       
        .giftcard{
            display: none;           
        }
    
        .footer{
            position: absolute;
            min-width: 100vw;
            display: flex;
            z-index: 10;
            margin-top: -25vh;
            flex-direction: column;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                margin-bottom: 5vh;
                label{
                    min-width: 95vw;
                    max-width: 95vw;          
                    font-size: .9rem;        
                    font-family: Avenir;
                    font-weight: 400;
                    line-height: 1.5rem;
                  
                    a{
                        color: #FFF;
                    }
                    
                }
                img{
                    display: none;                 
                }
            }
        }
        
    }
    
}

@media (min-width:393px) and (min-height: 873px)  {

}

@media (min-width:412px) and (min-height: 915px)  {
    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #968B6F 0%, #C8B29B 30%, #C8B29B 50%, #FFF 80%,#FFF 100% );
    
        .imagenLogo{
            position: absolute;
            min-width: 50vw;
            margin-top: 101vh;
            margin-left: 25vw;
        }
        .sectionOne{
            min-height: 45vh;
            background-image: url('../../landing/fondoEnviadoMobil.png');
            background-size: cover;
    
            background-position: bottom;
            background-size: 110%;
            background-repeat: no-repeat;
    
    
            .header{
                display: flex;
                .footersectionOne{
                    min-width: 80vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    label{
                        min-width: 75vw;
                        max-width: 75vw;
                    }
                    .texone{
                        display: none;
                        
                    }
                    .textwo{
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{
                    position: absolute;
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        min-width: 10vw;
                    }
                }
            }
          
         
          
        }
    
    
        .sectionCard{
            max-width: 100vw;
            z-index: 2;
            min-height: 5vh;
            padding-bottom: 3vh; 
            padding-top: 3vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            max-width: 100vw;
            font-size: 1.8rem;
            text-align: center;
            color: #6E605D;
            font-family: Gabi;
            font-weight: 600;       
            font-style: normal;
            background-image: linear-gradient(to top, transparent 10%   ,transparent 100% );
            text{
                font-family: Avenir;
                font-weight: 400;       
                font-style: oblique;
            }
        }
    
    
        .sectionForm{
            
            z-index: 5;
            max-width: 100vw;
            display: flex;
            .filtro{    
                display: none;            
            }
            form{ 
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 5;

                img{
                    
                }
                div{
                    z-index: 5;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 10vh;
                    font-family: Avenir;
                    
                    .subtitleUno{
                        font-weight: 400;
                        margin-top: 0vh;
                        font-size: 1rem;
                        text-align: center;
                        letter-spacing: 0.05rem;
                        max-width: 100vw;
                        min-width: 100vw;
                        color: #FFF;
                        font-family: Avenir;
                        font-style: oblique;
                        margin-bottom: 3vh;
                        font-style: normal;
                    }
    
                    
                    input,select{             
                        display: none;
                    }
                    select{
                        max-width: 20vw;
                        min-width: 20vw;
                        display: none;
                    }
                    label{
                        z-index: 5;
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;

         
                    }
                    input::placeholder,  select::placeholder{
                        color: #FFF;
                        font-weight: 400;
                        font-size: 0.9rem;
                        text-align: left;
                        display: none;
                    }
                }
                .Logos{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                 
                    a{
                        text-decoration: none;
                    }
                    .logoUno{
                        text-align: center;
                        img{
                            max-width: 25vw;
                            
                        }

                        label{
                            min-width: 40vw;
                            max-width: 40vw;
                            font-size: 1rem;
                            margin-top: 0.5rem;
                            letter-spacing: 0rem;
                        }
                    }
                    .logoDos{
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img{
                            max-width: 20vw;

                        }
                        label{

                            font-size: 1rem;
                            letter-spacing: 0rem;
                            min-width: 30vw;
                            max-width: 30vw;
                            margin-top: 0.8rem;
                        }
                    }
                

                }

    
                
                .entradasRadios{                    
                    display: none;
                }
    
                .agendacita{
                    display: none;                 
                }
            }
    
        }
       
        .giftcard{
            display: none;           
        }
    
        .footer{
            position: absolute;
            min-width: 100vw;
            display: flex;
            z-index: 10;
            margin-top: -25vh;
            flex-direction: column;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                margin-bottom: 5vh;
                label{
                    min-width: 95vw;
                    max-width: 95vw;          
                    font-size: .9rem;        
                    font-family: Avenir;
                    font-weight: 400;
                    line-height: 1.5rem;
                  
                    a{
                        color: #FFF;
                    }
                    
                }
                img{
                    display: none;                 
                }
            }
        }
        
    }
}
@media (min-width:414px) and (min-height: 896px)  {

}


/*FIN RESOLUCIONES MOBILES*/















/* RESOLUCIONES  DESKTOP */
@media (min-width:1280px) and (min-height: 720px)  {
    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #6E605D 0%, #D5BFAA 30%, #D5BFAA 50%, #D2BCA7 60%, #D2BCA7 100% );
      
    
        display: flex;
        flex-direction: row;
    
        .imagenLogo{
            position: absolute;
            min-width: 20vw;
            margin-top: 5vh;
            margin-left: 70vw;
            img{
                min-width: 20vw;
            }
        }
        .sectionOne{
            min-width: 60vw;
            max-width: 60vw;
            min-height: 100vh;
            background-image: url('../../landing/fondoEnviadoDesktop.png');
            background-position: center;
            background-size: 105%;        
            background-position-x: -3vw;
            background-repeat: no-repeat;
    
    
            .header{
                display: flex;
                .footersectionOne{
                    min-width: 60vw;
                    margin-top: 10vh;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    label{

                        min-width: 75vw;
                        max-width: 75vw;
                    }
                    .texone{
                        display: none;                      
                    }
                    .textwo{
                        border: 1px solid green;
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{

                    position: absolute;
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        min-width: 10vw;
                    }
                }
            }
          
         
          
        }
    
    
        .sectionCard{
            position: absolute;
            z-index: 2;
            min-height: 5vh;
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 40vw;
            max-width: 40vw;
            margin-left: 59vw;
            margin-top: 25vh;
            font-size: 3rem;
            text-align: center;
            color: #6E605D;
            font-family: Gabi;       
            font-style: normal;
            background-image: linear-gradient(to top, transparent 0%  ,transparent 100% );
            br{
        
            }
            text{
                font-family: Avenir;
                font-size: 1.7rem;
                font-style: oblique;
                br{
                    display: none;
                }
            }
        }
    
    
        .sectionForm{
            position: absolute;

            margin-left: 60vw;
            z-index: 5;
            min-width: 40vw;
            max-width: 40vw;
            display: flex;
            flex-direction: column;
            .filtro{
                display: none;
            }
            form{ 
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 5;
                margin-top: 55vh;
                min-height: 20vh;
                max-height: 20vh;
                img{
                    
                }
                div{
                    z-index: 5;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 0h;
                    min-height: 0vh;
                    font-family: Avenir;
                    .subtitleUno{    
                        display: flex;                                   
                        font-weight: 400;
                        margin-top: 2vh;
                        font-size: 1.2rem;
                        text-align: center;
                        letter-spacing: 0.05rem;
                        max-width: 40vw;
                        min-width: 40vw;
                        color: #FFF;
                        font-family: Avenir;
                        font-style: oblique;
                        margin-bottom: 10vh;
                        br{
                            display: none;
                        }
                    }
    
    
                    input,select{
                        display: none;
                     
                    }
                    select{
                        display: none;
                    }
                    label{            
                        z-index: 5;
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
         
                    }
                    input::placeholder,  select::placeholder{
                        display: none;
                        color: #FFF;
                        font-weight: 400;
                        font-size: 0.9rem;
                        text-align: left;
                    }
                }
    
                .flechasImagen{
                    display: none;
                }
                .Logos{
                    margin-top: 15vh;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                 
                    a{
                        text-decoration: none;
                    }
                    .logoUno{
                        text-align: center;
                        img{
                            max-width: 25vw;
                            
                        }
                        label{
                            min-width: 10vw;
                            max-width: 10vw;
                            font-size: 1rem;
                            margin-top: 0.5rem;
                            letter-spacing: 0rem;
                        }
                    }
                    .logoDos{
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img{
                            max-width: 20vw;
                        }
                        label{
                            font-size: 1rem;
                            letter-spacing: 0rem;
                            min-width: 10vw;
                            max-width: 10vw;
                            margin-top: 0.8rem;
                        }
                    }
                

                }
              
                .entradasRadios{
                    display: none;                                             
                }
    
                .agendacita{
                    display: none;                 
                }
            }
            
        }
    

        .giftcard{
            display: none;
        }
    
        .footer{
            position: absolute;
            min-width: 40vw;
            display: flex;
            flex-direction: column;
            margin-top: 92vh;
            min-height: 5vh;
            max-height: 5vh;
            margin-left: 59vw;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                margin-top: -1vh;
                label{
                    min-width: 18vw;
                    max-width: 24vw;          
                    font-size: 0.8rem;        
                    font-family: Avenir;
                    
                    font-weight: 400;
                    line-height: 1.5rem;
                  
                    a{
                        min-width: 20vw;
                    }
                    
                }
                img{
                    display: none;                 
                }
            }
        }
        
    }
    
}

@media (min-width:1440px) and (min-height: 900px)  {
    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #6E605D 0%, #D5BFAA 30%, #D5BFAA 50%, #D2BCA7 60%, #D2BCA7 100% );
      
    
        display: flex;
        flex-direction: row;
    
        .imagenLogo{
            position: absolute;
            min-width: 20vw;
            margin-top: 5vh;
            margin-left: 70vw;
            img{
                min-width: 20vw;
            }
        }
        .sectionOne{
            min-width: 60vw;
            max-width: 60vw;
            min-height: 100vh;
            background-image: url('../../landing/fondoEnviadoDesktop.png');
            background-position: center;
            background-size: 115%;        
            background-position-x: -10vw;
            background-repeat: no-repeat;
    
    
            .header{
                display: flex;
                .footersectionOne{
                    min-width: 60vw;
                    margin-top: 10vh;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    label{

                        min-width: 75vw;
                        max-width: 75vw;
                    }
                    .texone{
                        display: none;                      
                    }
                    .textwo{
                        border: 1px solid green;
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{

                    position: absolute;
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        min-width: 10vw;
                    }
                }
            }
          
         
          
        }
    
    
        .sectionCard{
            position: absolute;
            z-index: 2;
            min-height: 5vh;
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 40vw;
            max-width: 40vw;
            margin-left: 59vw;
            margin-top: 25vh;
            font-size: 3rem;
            text-align: center;
            color: #6E605D;
            font-family: Gabi;       
            font-style: normal;
            background-image: linear-gradient(to top, transparent 0%  ,transparent 100% );
            br{
        
            }
            text{
                font-family: Avenir;
                font-size: 1.7rem;
                font-style: oblique;
                br{
                    display: none;
                }
            }
        }
    
    
        .sectionForm{
            position: absolute;

            margin-left: 60vw;
            z-index: 5;
            min-width: 40vw;
            max-width: 40vw;
            display: flex;
            flex-direction: column;
            .filtro{
                display: none;
            }
            form{ 
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 5;
                margin-top: 55vh;
                min-height: 20vh;
                max-height: 20vh;
                img{
                    
                }
                div{
                    z-index: 5;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 0h;
                    min-height: 0vh;
                    font-family: Avenir;
                    .subtitleUno{    
                        display: flex;                                   
                        margin-top: 2vh;
                        font-size: 1.4rem;
                        font-weight: 600;
                        text-align: center;
                        letter-spacing: 0.05rem;
                        max-width: 30vw;
                        min-width: 30vw;
                        margin-left: 4vw;
                        color: #FFF;
                        font-family: Avenir;
                        font-style: normal;
                        margin-bottom: 10vh;
                        text-align: center;
                        
                        br{
                            display: none;
                        }
                    }
    
    
                    input,select{
                        display: none;
                     
                    }
                    select{
                        display: none;
                    }
                    label{            
                        z-index: 5;
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
         
                    }
                    input::placeholder,  select::placeholder{
                        display: none;
                        color: #FFF;
                        font-weight: 400;
                        font-size: 0.9rem;
                        text-align: left;
                    }
                }
    
                .flechasImagen{
                    display: none;
                }
                .Logos{
                    margin-top: 15vh;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                 
                    a{
                        text-decoration: none;
                    }
                    .logoUno{
                        text-align: center;
                        margin-right: 3vw;
                        margin-left: 5vw;
                        img{
                            max-width: 25vw;
                            
                        }
                        label{
                            min-width: 10vw;
                            max-width: 10vw;
                            font-size: 1rem;
                            margin-top: 0.5rem;
                            letter-spacing: 0rem;
                        }
                    }
                    .logoDos{
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img{
                            max-width: 20vw;
                        }
                        label{
                            font-size: 1rem;
                            letter-spacing: 0rem;
                            min-width: 10vw;
                            max-width: 10vw;
                            margin-top: 0.8rem;
                        }
                    }
                

                }
              
                .entradasRadios{
                    display: none;                                             
                }
    
                .agendacita{
                    display: none;                 
                }
            }
            
        }
    

        .giftcard{
            display: none;
        }
    
        .footer{
            position: absolute;
            min-width: 40vw;
            display: flex;
            flex-direction: column;
            margin-top: 92vh;
            min-height: 5vh;
            max-height: 5vh;
            margin-left: 59vw;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                margin-top: -1vh;
                label{
                    min-width: 18vw;
                    max-width: 24vw;          
                    font-size: 0.8rem;        
                    font-family: Avenir;
                    
                    font-weight: 400;
                    line-height: 1.5rem;
                  
                    a{
                        min-width: 20vw;
                    }
                    
                }
                img{
                    display: none;                 
                }
            }
        }
        
    }
    
}

@media (min-width:1366px) and (min-height: 768px)  {
    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #6E605D 0%, #D5BFAA 30%, #D5BFAA 50%, #D2BCA7 60%, #D2BCA7 100% );
      
    
        display: flex;
        flex-direction: row;
    
        .imagenLogo{
            position: absolute;
            min-width: 20vw;
            margin-top: 5vh;
            margin-left: 70vw;
            img{
                min-width: 20vw;
            }
        }
        .sectionOne{
            min-width: 60vw;
            max-width: 60vw;
            min-height: 100vh;
            background-image: url('../../landing/fondoEnviadoDesktop.png');
            background-position: center;
            background-size: 115%;        
            background-position-x: -10vw;
            background-repeat: no-repeat;
    
    
            .header{
                display: flex;
                .footersectionOne{
                    min-width: 60vw;
                    margin-top: 10vh;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    label{

                        min-width: 75vw;
                        max-width: 75vw;
                    }
                    .texone{
                        display: none;                      
                    }
                    .textwo{
                        border: 1px solid green;
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{

                    position: absolute;
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        min-width: 10vw;
                    }
                }
            }
          
         
          
        }
    
    
        .sectionCard{
            position: absolute;
            z-index: 2;
            min-height: 5vh;
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 40vw;
            max-width: 40vw;
            margin-left: 59vw;
            margin-top: 25vh;
            font-size: 3rem;
            text-align: center;
            color: #6E605D;
            font-family: Gabi;       
            font-style: normal;
            background-image: linear-gradient(to top, transparent 0%  ,transparent 100% );
            br{
        
            }
            text{
                font-family: Avenir;
                font-size: 1.7rem;
                font-style: oblique;
                br{
                    display: none;
                }
            }
        }
    
    
        .sectionForm{
            position: absolute;

            margin-left: 60vw;
            z-index: 5;
            min-width: 40vw;
            max-width: 40vw;
            display: flex;
            flex-direction: column;
            .filtro{
                display: none;
            }
            form{ 
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 5;
                margin-top: 55vh;
                min-height: 20vh;
                max-height: 20vh;
                img{
                    
                }
                div{
                    z-index: 5;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 0h;
                    min-height: 0vh;
                    font-family: Avenir;
                    .subtitleUno{    
                        display: flex;                                   
                        margin-top: 2vh;
                        font-size: 1.4rem;
                        font-weight: 600;
                        text-align: center;
                        letter-spacing: 0.05rem;
                        max-width: 32vw;
                        min-width: 32vw;
                        margin-left: 4vw;
                        color: #FFF;
                        font-family: Avenir;
                        font-style: normal;
                        margin-bottom: 10vh;
                        text-align: center;
                        
                        br{
                            display: none;
                        }
                    }
    
    
                    input,select{
                        display: none;
                     
                    }
                    select{
                        display: none;
                    }
                    label{            
                        z-index: 5;
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
         
                    }
                    input::placeholder,  select::placeholder{
                        display: none;
                        color: #FFF;
                        font-weight: 400;
                        font-size: 0.9rem;
                        text-align: left;
                    }
                }
    
                .flechasImagen{
                    display: none;
                }
                .Logos{
                    margin-top: 15vh;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                 
                    a{
                        text-decoration: none;
                    }
                    .logoUno{
                        text-align: center;
                        margin-right: 3vw;
                        margin-left: 3vw;
                        img{
                            max-width: 25vw;
                            
                        }
                        label{
                            min-width: 10vw;
                            max-width: 10vw;
                            font-size: 1rem;
                            margin-top: 0.5rem;
                            letter-spacing: 0rem;
                        }
                    }
                    .logoDos{
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img{
                            max-width: 20vw;
                        }
                        label{
                            font-size: 1rem;
                            letter-spacing: 0rem;
                            min-width: 10vw;
                            max-width: 10vw;
                            margin-top: 0.8rem;
                        }
                    }
                

                }
              
                .entradasRadios{
                    display: none;                                             
                }
    
                .agendacita{
                    display: none;                 
                }
            }
            
        }
    

        .giftcard{
            display: none;
        }
    
        .footer{
            position: absolute;
            min-width: 40vw;
            display: flex;
            flex-direction: column;
            margin-top: 92vh;
            min-height: 5vh;
            max-height: 5vh;
            margin-left: 59vw;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                margin-top: -1vh;
                label{
                    min-width: 18vw;
                    max-width: 24vw;          
                    font-size: 0.8rem;        
                    font-family: Avenir;
                    
                    font-weight: 400;
                    line-height: 1.5rem;
                  
                    a{
                        min-width: 20vw;
                    }
                    
                }
                img{
                    display: none;                 
                }
            }
        }
        
    }
}

@media (min-width:1536px) and (min-height: 864px)  {
    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #6E605D 0%, #D5BFAA 30%, #D5BFAA 50%, #D2BCA7 60%, #D2BCA7 100% );
      
    
        display: flex;
        flex-direction: row;
    
        .imagenLogo{
            position: absolute;
            min-width: 20vw;
            margin-top: 5vh;
            margin-left: 70vw;
            img{
                min-width: 20vw;
            }
        }
        .sectionOne{
            min-width: 60vw;
            max-width: 60vw;
            min-height: 100vh;
            background-image: url('../../landing/fondoEnviadoDesktop.png');
            background-position: center;
            background-size: 115%;        
            background-position-x: -10vw;
            background-repeat: no-repeat;
    
    
            .header{
                display: flex;
                .footersectionOne{
                    min-width: 60vw;
                    margin-top: 10vh;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    label{

                        min-width: 75vw;
                        max-width: 75vw;
                    }
                    .texone{
                        display: none;                      
                    }
                    .textwo{
                        border: 1px solid green;
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{

                    position: absolute;
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        min-width: 10vw;
                    }
                }
            }
          
         
          
        }
    
    
        .sectionCard{
            position: absolute;
            z-index: 2;
            min-height: 5vh;
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 40vw;
            max-width: 40vw;
            margin-left: 59vw;
            margin-top: 25vh;
            font-size: 3rem;
            text-align: center;
            color: #6E605D;
            font-family: Gabi;       
            font-style: normal;
            background-image: linear-gradient(to top, transparent 0%  ,transparent 100% );
            br{
        
            }
            text{
                font-family: Avenir;
                font-size: 1.7rem;
                font-style: oblique;
                br{
                    display: none;
                }
            }
        }
    
    
        .sectionForm{
            position: absolute;

            margin-left: 60vw;
            z-index: 5;
            min-width: 40vw;
            max-width: 40vw;
            display: flex;
            flex-direction: column;
            .filtro{
                display: none;
            }
            form{ 
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 5;
                margin-top: 55vh;
                min-height: 20vh;
                max-height: 20vh;
                img{
                    
                }
                div{
                    z-index: 5;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 0h;
                    min-height: 0vh;
                    font-family: Avenir;
                    .subtitleUno{    
                        display: flex;                                   
                        margin-top: 2vh;
                        font-size: 1.4rem;
                        font-weight: 600;
                        text-align: center;
                        letter-spacing: 0.05rem;
                        max-width: 30vw;
                        min-width: 30vw;
                        margin-left: 4vw;
                        color: #FFF;
                        font-family: Avenir;
                        font-style: normal;
                        margin-bottom: 10vh;
                        text-align: center;
                        
                        br{
                            display: none;
                        }
                    }
    
    
                    input,select{
                        display: none;
                     
                    }
                    select{
                        display: none;
                    }
                    label{            
                        z-index: 5;
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
         
                    }
                    input::placeholder,  select::placeholder{
                        display: none;
                        color: #FFF;
                        font-weight: 400;
                        font-size: 0.9rem;
                        text-align: left;
                    }
                }
    
                .flechasImagen{
                    display: none;
                }
                .Logos{
                    margin-top: 15vh;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                 
                    a{
                        text-decoration: none;
                    }
                    .logoUno{
                        text-align: center;
                        margin-right: 3vw;
                        margin-left: 3vw;
                        img{
                            max-width: 25vw;
                            
                        }
                        label{
                            min-width: 10vw;
                            max-width: 10vw;
                            font-size: 1.1rem;
                            margin-top: 0.5rem;
                            letter-spacing: 0rem;
                        }
                    }
                    .logoDos{
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img{
                            max-width: 20vw;
                        }
                        label{
                            font-size: 1.1rem;
                            letter-spacing: 0rem;
                            min-width: 10vw;
                            max-width: 10vw;
                            margin-top: 0.8rem;
                        }
                    }
                

                }
              
                .entradasRadios{
                    display: none;                                             
                }
    
                .agendacita{
                    display: none;                 
                }
            }
            
        }
    

        .giftcard{
            display: none;
        }
    
        .footer{
            position: absolute;
            min-width: 40vw;
            display: flex;
            flex-direction: column;
            margin-top: 92vh;
            min-height: 5vh;
            max-height: 5vh;
            margin-left: 59vw;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                margin-top: -1vh;
                label{
                    min-width: 18vw;
                    max-width: 24vw;          
                    font-size: 0.8rem;        
                    font-family: Avenir;
                    
                    font-weight: 400;
                    line-height: 1.5rem;
                  
                    a{
                        min-width: 20vw;
                    }
                    
                }
                img{
                    display: none;                 
                }
            }
        }
        
    }
}

@media (min-width:1600px) and (min-height: 900px)  {
    .generalContainerLanding{
        min-width: 100vw;
        max-width: 100vw;
        min-height: 100vh;
        background-image: linear-gradient(to top, #6E605D 0%, #D5BFAA 30%, #D5BFAA 50%, #D2BCA7 60%, #D2BCA7 100% );
      
    
        display: flex;
        flex-direction: row;
    
        .imagenLogo{
            position: absolute;
            min-width: 20vw;
            margin-top: 5vh;
            margin-left: 68.5vw;
            img{
                min-width: 20vw;
            }
        }
        .sectionOne{
            min-width: 60vw;
            max-width: 60vw;
            min-height: 100vh;
            background-image: url('../../landing/fondoEnviadoDesktop.png');
            background-position: center;
            background-size: 115%;        
            background-position-x: -10vw;
            background-repeat: no-repeat;
    
    
            .header{
                display: flex;
                .footersectionOne{
                    min-width: 60vw;
                    margin-top: 10vh;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                    label{

                        min-width: 75vw;
                        max-width: 75vw;
                    }
                    .texone{
                        display: none;                      
                    }
                    .textwo{
                        border: 1px solid green;
                        color: #fff;
                        font-size: 1.3rem;
                        min-height: 8vh;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: Avenir;
                        font-style: oblique;
                    }
                }
                .footersectionTwo{

                    position: absolute;
                    min-width: 20vw;
                    min-height: 10vh;
                    max-height: 10vh;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        min-width: 10vw;
                    }
                }
            }
          
         
          
        }
    
    
        .sectionCard{
            position: absolute;
            z-index: 2;
            min-height: 5vh;
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-width: 40vw;
            max-width: 40vw;
            margin-left: 59vw;
            margin-top: 25vh;
            font-size: 3rem;
            text-align: center;
            color: #6E605D;
            font-family: Gabi;       
            font-style: normal;
            background-image: linear-gradient(to top, transparent 0%  ,transparent 100% );
            br{
        
            }
            text{
                font-family: Avenir;
                font-size: 1.7rem;
                font-style: oblique;
                br{
                    display: none;
                }
            }
        }
    
    
        .sectionForm{
            position: absolute;

            margin-left: 60vw;
            z-index: 5;
            min-width: 40vw;
            max-width: 40vw;
            display: flex;
            flex-direction: column;
            .filtro{
                display: none;
            }
            form{ 
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 5;
                margin-top: 55vh;
                min-height: 20vh;
                max-height: 20vh;
                img{
                    
                }
                div{
                    z-index: 5;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    min-height: 0h;
                    min-height: 0vh;
                    font-family: Avenir;
                    .subtitleUno{    
                        display: flex;                                   
                        margin-top: 2vh;
                        font-size: 1.4rem;
                        font-weight: 600;
                        text-align: center;
                        letter-spacing: 0.05rem;
                        max-width: 30vw;
                        min-width: 30vw;
                        margin-left: 4vw;
                        color: #FFF;
                        font-family: Avenir;
                        font-style: normal;
                        margin-bottom: 10vh;
                        text-align: center;
                        
                        br{
                            display: none;
                        }
                    }
    
    
                    input,select{
                        display: none;
                     
                    }
                    select{
                        display: none;
                    }
                    label{            
                        z-index: 5;
                        font-size: 1rem;
                        color:#FFF;
                        letter-spacing: 0.2rem;
         
                    }
                    input::placeholder,  select::placeholder{
                        display: none;
                        color: #FFF;
                        font-weight: 400;
                        font-size: 0.9rem;
                        text-align: left;
                    }
                }
    
                .flechasImagen{
                    display: none;
                }
                .Logos{
                    margin-top: 15vh;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                 
                    a{
                        text-decoration: none;
                    }
                    .logoUno{
                        text-align: center;
                        margin-right: 3vw;
                        margin-left: 3vw;
                        img{
                            max-width: 25vw;
                            
                        }
                        label{
                            min-width: 10vw;
                            max-width: 10vw;
                            font-size: 1rem;
                            margin-top: 0.5rem;
                            letter-spacing: 0rem;
                        }
                    }
                    .logoDos{
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img{
                            max-width: 20vw;
                        }
                        label{
                            font-size: 1rem;
                            letter-spacing: 0rem;
                            min-width: 10vw;
                            max-width: 10vw;
                            margin-top: 0.8rem;
                        }
                    }
                

                }
              
                .entradasRadios{
                    display: none;                                             
                }
    
                .agendacita{
                    display: none;                 
                }
            }
            
        }
    

        .giftcard{
            display: none;
        }
    
        .footer{
            position: absolute;
            min-width: 40vw;
            display: flex;
            flex-direction: column;
            margin-top: 92vh;
            min-height: 5vh;
            max-height: 5vh;
            margin-left: 59vw;
            div{
                display: flex;
                flex-direction: column;
                text-align: center;
                color: #FFF;
                justify-content: center;
                align-items: center;
                margin-top: -1vh;
                label{
                    min-width: 18vw;
                    max-width: 24vw;          
                    font-size: 0.8rem;        
                    font-family: Avenir;
                    
                    font-weight: 400;
                    line-height: 1.5rem;
                  
                    a{
                        min-width: 20vw;
                    }
                    
                }
                img{
                    display: none;                 
                }
            }
        }
        
    }
}

@media (min-width:1920px) and (min-height: 1080px)  {

}





/* FIN RESOLUCIONES DESKTOP*/























